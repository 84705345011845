interface GetTruncatesTextProps {
  text: string;
  maxLength?: number;
}

const getTruncatedText = ({ text, maxLength = 50 }: GetTruncatesTextProps) => {
  if (text.length <= maxLength) return text;

  const truncatedText = `${text.substring(0, maxLength - 3)}...`;
  return truncatedText;
};

export default getTruncatedText;
