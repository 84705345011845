// eslint-disable @typescript-eslint/no-unsafe-member-access
// eslint-disable @typescript-eslint/no-unsafe-assignment
import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import GlobalErrorBoundaryFallback from 'components/GlobalErrorBoundaryFallback';
import NotFoundFallback from 'components/NotFoundFallback';
import type { FC, PropsWithChildren } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

interface ErrorEntry {
  cause?: { status?: number; error?: string };
}

const GlobalErrorBoundary: FC<PropsWithChildren> = ({ children }) => {
  const { reset } = useQueryErrorResetBoundary();

  return (
    <ErrorBoundary
      onReset={reset}
      FallbackComponent={(props) => {
        if ((props.error as ErrorEntry).cause?.status === 404) {
          return <NotFoundFallback {...props} error={(props.error as ErrorEntry).cause?.error} />;
        }
        return <GlobalErrorBoundaryFallback {...props} />;
      }}
    >
      {children}
    </ErrorBoundary>
  );
};

export default GlobalErrorBoundary;
