import { Box, Button, Paper, Typography } from '@mui/material';
import { useEffect, type FC, useState } from 'react';
import type { FallbackProps } from 'react-error-boundary';
import { Layout } from './layout';
import { Titled } from 'react-titled';
import { useLocation, useNavigate } from 'react-router';

const GlobalErrorBoundaryFallback: FC<FallbackProps> = ({ resetErrorBoundary }) => {
  const [isMounted, setIsMounted] = useState(false);
  const [locationKey, setLocationKey] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  const handleReset = () => {
    resetErrorBoundary();
  };

  useEffect(() => {
    if (isMounted && !locationKey.length) {
      setLocationKey('default');
      return;
    }

    if (isMounted && locationKey !== location.key) {
      // App routers are not available here,
      // so we need to follow location changes manually,
      // and apply a synchronous page reload when using some navigation...
      resetErrorBoundary();
      navigate(location.pathname, { replace: true });
      return;
    }

    if (!isMounted) {
      setIsMounted(true);
      return;
    }

    return () => {
      setIsMounted(false);
      setLocationKey('default');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, locationKey, resetErrorBoundary, setIsMounted, setLocationKey, navigate]);

  return (
    <Titled title="Assets">
      <Layout>
        <Box sx={{ p: 4 }}>
          <Paper sx={{ p: 4, display: 'inline-block' }} variant="outlined">
            <Typography variant="h2" component="h1">
              An unexpected error happened :(
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              I hope this won&apos;t happen to you again.
            </Typography>
            <Button variant="contained" size="large" onClick={handleReset} sx={{ mt: 2 }}>
              Reload page
            </Button>
          </Paper>
        </Box>
      </Layout>
    </Titled>
  );
};

export default GlobalErrorBoundaryFallback;
