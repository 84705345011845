import { CssBaseline, ThemeProvider } from '@mui/material';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import GlobalErrorBoundary from 'GlobalErrorBoundary';
import { Amplify } from 'aws-amplify';
import config from 'config';
import { queryClient } from 'lib/react-query';
import { SnackbarProvider } from 'notistack';
import queryString from 'query-string';
import type { FC } from 'react';
import React, { Suspense } from 'react';
import { Navigate, Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Titled } from 'react-titled';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import AuthRequired from 'lib/auth/AuthRequired';
import ProgressSkeleton from 'components/layout/ProgressSkeleton';
import Login from 'pages/login';
import LoginAutoTest from 'pages/login-autotest';
import { Authenticator } from '@aws-amplify/ui-react';
import { Layout } from 'components/layout';
import AssetsPage from 'pages/assets';
import UsersPage from 'pages/users';
import AssetDetailsPage from './pages/assets/AssetDetailsPage';
import theme from './theme';
import ForbiddenPage from './pages/forbidden';
import TagsPage from 'pages/tags';
import CreateAsset from './pages/assets/CreateAsset';
import UpdateAsset from './pages/assets/UpdateAsset';
import CreativesPage from './pages/creatives';
import CreativeDetailsPage from 'pages/creatives/CreativeDetailsPage';
import CreativeEditPage from 'pages/creatives/CreativeEditPage';
import HotjarInitial from 'lib/hotjar/HotjarInitial';
import MaintenancePage from 'pages/maintenance';

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.REGION,
    userPoolId: config.COGNITO_USER_POOL_ID,
    userPoolWebClientId: config.COGNITO_APP_CLIENT_ID,
    oauth: {
      domain: config.COGNITO_DOMAIN,
      scope: ['email', 'openid'],
      redirectSignIn: config.COGNITO_REDIRECT_SIGN_IN ?? `${window.location.origin}/`,
      redirectSignOut: config.COGNITO_REDIRECT_SIGN_OUT ?? `${window.location.origin}/`,
      responseType: 'code',
    },
  },
});

const Providers: FC = () => (
  <QueryParamProvider
    adapter={ReactRouter6Adapter}
    options={{
      searchStringToObject: (str) => queryString.parse(str, { arrayFormat: 'comma' }),
      objectToSearchString: (input) => queryString.stringify(input, { arrayFormat: 'comma' }),
    }}
  >
    <CssBaseline />
    <Authenticator.Provider>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <GlobalErrorBoundary>
            <SnackbarProvider
              autoHideDuration={1000}
              maxSnack={5}
              anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
              preventDuplicate
            >
              <HotjarInitial>
                <Suspense fallback={<ProgressSkeleton />}>
                  <Outlet />
                </Suspense>
              </HotjarInitial>
            </SnackbarProvider>
          </GlobalErrorBoundary>
        </ThemeProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </Authenticator.Provider>
  </QueryParamProvider>
);

const router = createBrowserRouter([
  {
    element: (
      <Titled title="ADAM">
        <Providers />
      </Titled>
    ),
    children: [
      {
        index: true,
        path: '/assets',
        element: (
          <AuthRequired>
            <Titled title="Assets">
              <Layout>
                <AssetsPage />
              </Layout>
            </Titled>
          </AuthRequired>
        ),
      },
      {
        path: '/creatives',
        children: [
          {
            index: true,
            path: '',
            element: (
              <AuthRequired>
                <Titled title={(title) => `ADAM | ${title}`} />
                <CreativesPage />
              </AuthRequired>
            ),
          },
          {
            path: ':creativeId',
            element: (
              <AuthRequired>
                <Titled title={(title) => `Creative details | ${title}`} />
                <CreativeDetailsPage />
              </AuthRequired>
            ),
          },
          {
            path: ':creativeId/edit',
            element: (
              <AuthRequired>
                <Titled title={(title) => `Creative edit | ${title}`} />
                <CreativeEditPage />
              </AuthRequired>
            ),
          },
        ],
      },
      {
        path: '/assets',
        children: [
          {
            index: true,
            path: '',
            element: (
              <AuthRequired>
                <Titled title="Assets">
                  <Layout>
                    <AssetsPage />
                  </Layout>
                </Titled>
              </AuthRequired>
            ),
          },
          {
            path: ':assetId',
            element: (
              <AuthRequired>
                <AssetDetailsPage />
              </AuthRequired>
            ),
          },
          {
            path: 'create/:assetId?',
            element: (
              <AuthRequired>
                <CreateAsset />
              </AuthRequired>
            ),
          },
          {
            path: 'update/:assetId',
            element: (
              <AuthRequired>
                <UpdateAsset />
              </AuthRequired>
            ),
          },
        ],
      },
      {
        path: '/tags',
        element: (
          <AuthRequired>
            <Titled title="Tags">
              <Layout>
                <TagsPage />
              </Layout>
            </Titled>
          </AuthRequired>
        ),
      },
      {
        path: '/users',
        element: (
          <AuthRequired>
            <Titled title="Users">
              <Layout>
                <UsersPage />
              </Layout>
            </Titled>
          </AuthRequired>
        ),
      },
      {
        path: '/login',
        element: (
          <Titled title="Login">
            <Login />
          </Titled>
        ),
      },
      {
        path: '/login/autotest',
        element: (
          <Titled title="Login AutoTest">
            <LoginAutoTest />
          </Titled>
        ),
      },
      {
        path: '/forbidden',
        element: (
          <AuthRequired checkAccess={false}>
            <ForbiddenPage />
          </AuthRequired>
        ),
      },
      {
        path: '/maintenance',
        element: (
          <AuthRequired>
            <MaintenancePage />
          </AuthRequired>
        ),
      },
      { path: '*', element: <Navigate to="/assets" replace /> },
    ],
  },
]);

const App: FC = () => (
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

export default App;
