/* eslint-disable @typescript-eslint/no-explicit-any */

export function persistData(key: string, data: any) {
  localStorage.setItem(key, JSON.stringify(data));
}

export function getPersistedData<T>(key: string, defaultValue: T): T;

export function getPersistedData<T>(key: string, defaultValue?: T | undefined): T | undefined;

export function getPersistedData<T = unknown>(key: string, defaultValue?: T): T | undefined {
  const fromStorage = localStorage.getItem(key);
  if (!fromStorage) {
    if (defaultValue === undefined) {
      return undefined;
    }

    persistData(key, defaultValue);
    return defaultValue;
  }
  try {
    const data = JSON.parse(fromStorage) as T;
    return data;
  } catch (_) {
    persistData(key, defaultValue);
    return defaultValue;
  }
}
