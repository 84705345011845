import { useCallback, useState } from 'react';

export function useAnchorMenu<T = HTMLElement>() {
  const [anchor, setAnchor] = useState<null | T>(null);

  const handleOpenMenu = useCallback((event: React.MouseEvent<T>) => {
    setAnchor(event.currentTarget);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setAnchor(null);
  }, []);

  return {
    isOpen: Boolean(anchor),
    anchor,
    handleOpenMenu,
    handleCloseMenu,
  };
}
