import type { QueryKey } from '@tanstack/react-query';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { QueryConfig, SynchronousQueryConfig } from 'lib/react-query';
import type { AssetDetailsModel } from './types';
import queryKeys from './queryKeys';
import axios from 'axios';

export const getAsset = async (id: number, signal?: AbortSignal): Promise<AssetDetailsModel> => {
  const res = await apiClient.get<{ data: AssetDetailsModel }>(`/assets/${id}`, {
    signal,
  });
  if (axios.isAxiosError(res)) {
    const error = new Error('Axios error');
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    error.cause = {
      ...res.response?.data,
      status: res.response?.status,
    };
    return Promise.reject(error);
  }
  return res.data.data;
};

interface UseAssetOptions<TData> {
  config?: QueryConfig<typeof getAsset, TData>;
  id: number;
}

interface UseAssetSyncOptions<TData> {
  config?: SynchronousQueryConfig<typeof getAsset, TData>;
  id: number;
}

export const useAsset = <TData = AssetDetailsModel>({ id, config }: UseAssetOptions<TData>) => {
  return useSuspenseQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: ({ signal }) => getAsset(id, signal),
    queryKey: queryKeys.asset(id),
  });
};

export const useSynchronousAsset = <TData = AssetDetailsModel>({ id, config }: UseAssetSyncOptions<TData>) => {
  return useQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: ({ signal }) => getAsset(id, signal),
    queryKey: ['assets', id] as QueryKey,
  });
};
