/* eslint-disable */
import { useMutation } from '@tanstack/react-query';
import type { MutationConfig } from 'lib/react-query';
import Axios from 'axios';
import config from 'config';

const apiClient = Axios.create({
  baseURL: config.ADMIN_API_URL,
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

interface AuthAutoTest {
  email: string;
  password: string;
}

export const authAutoTest = async (body: AuthAutoTest): Promise<Record<string, string>> => {
  const res = await apiClient.post<Record<string, string>>(`/oauth2/token/autotest`, body);

  if (Axios.isAxiosError(res)) {
    throw new Error('Auth error');
  }

  return res.data;
};

interface UseAuthAutoTestOptions {
  config?: MutationConfig<typeof authAutoTest>;
}

export const useAuthAutoTest = ({ config }: UseAuthAutoTestOptions = {}) => {
  return useMutation({
    ...config,
    onSuccess: (...args) => {
      if (config?.onSuccess) {
        config.onSuccess(...args);
      }
    },
    mutationFn: authAutoTest,
  });
};
