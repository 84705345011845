import { Box } from '@mui/material';
import LoginForm from './LoginForm';

const Login = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', minWidth: 320 }}>
      <LoginForm />
    </Box>
  );
};

export default Login;
