/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-explicit-any */

declare global {
  interface Window {
    hj: any;
  }
}

export default function hotjarIdentify(userId: any, email: string) {
  if (window.hj) {
    window.hj('identify', userId, {
      email,
    });
  }
}
