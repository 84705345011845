import { useCallback, useMemo, useState } from 'react';

export interface UseModalResult {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  toggle: () => void;
}

export function useModal(defaultState = false): UseModalResult {
  const [isOpen, setIsOpen] = useState<boolean>(defaultState);

  const toggle = useCallback(() => {
    setIsOpen((state) => !state);
  }, []);

  const open = useCallback(() => {
    setIsOpen(true);
  }, []);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  return useMemo(
    () => ({
      isOpen,
      open,
      close,
      toggle,
    }),
    [close, isOpen, open, toggle]
  );
}
