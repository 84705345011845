import { Box, Container, Typography } from '@mui/material';
import { useState, type FC, useEffect } from 'react';
import type { FallbackProps } from 'react-error-boundary';
import { Layout } from './layout';
import { Titled } from 'react-titled';
import BreadcrumbLink from './layout/BreadcrumbLink';
import { useLocation, useNavigate } from 'react-router';

const NotFoundFallback: FC<FallbackProps> = ({ resetErrorBoundary, error }) => {
  const [isMounted, setIsMounted] = useState(false);
  const [locationKey, setLocationKey] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  // App routers are not available here,
  // so we need to follow location changes manually,
  // and apply a synchronous page reload when using some navigation...
  useEffect(() => {
    if (isMounted && !locationKey.length) {
      setLocationKey('default');
      return;
    }

    if (isMounted && locationKey !== location.key) {
      resetErrorBoundary();
      navigate(location.pathname, { replace: true });
      return;
    }

    if (!isMounted) {
      setIsMounted(true);
      return;
    }

    return () => {
      setIsMounted(false);
      setLocationKey('default');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, locationKey, resetErrorBoundary, setIsMounted, setLocationKey]);

  return (
    <Layout>
      <Titled title={(title) => `Asset details | ${title}`} />
      <Box
        sx={(t) => ({
          position: 'relative',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'start',
          [t.breakpoints.down('sm')]: {
            pt: 6,
          },
          [t.breakpoints.up('sm')]: {
            pt: 1,
          },
          mb: 2,
          height: 48,
        })}
      >
        <BreadcrumbLink to="/assets" title="Assets" />
      </Box>
      <Container maxWidth={false} sx={{ '&': { p: 0 } }}>
        <Typography variant="h1" sx={(t) => ({ color: t.palette.text.disabled })}>
          {error ?? 'Not found'}
        </Typography>
      </Container>
    </Layout>
  );
};

export default NotFoundFallback;
